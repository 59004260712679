import React, { Component } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';

import CampaignListItem from '../../components/Campaigns/CampaignListItem';
import {
  getCampaignsAction,
  updateCampaignAction,
  getCampaignAction,
  deleteCampaignAction
} from '../../store/actions/campaign';
import { createProspectAction } from '../../store/actions/prospect';

class Campaigns extends Component {
  constructor(props) {
    super(props);

    this.createTestLead = this.createTestLead.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.redirectToEdit = this.redirectToEdit.bind(this);
    this.toggleRunning = this.toggleRunning.bind(this);
  }

  componentDidMount() {
    this.refreshCampaigns();
  }

  createTestLead = async data => {
    const { createLead, history } = this.props;
    try {
      await createLead(data);
      history.push('/leads');
    } catch (err) {
      console.log(err);
    }
  };

  toggleRunning = async (_id, running) => {
    const { editCampaign } = this.props;

    try {
      await editCampaign(_id, { running });
      this.refreshCampaigns();
    } catch (err) {
      console.log(err);
    }
  };

  handleClick = async () => {
    const { history } = this.props;

    history.push('/campaigns/new');
    return false;
  };

  handleDeleteCampaign = async _id => {
    const { deleteCampaign } = this.props;
    try {
      await deleteCampaign(_id);
    } catch (err) {
      console.log(err);
    }
    this.refreshCampaigns();
  };

  redirectToEdit(_id) {
    const { history } = this.props;

    history.push(`/campaigns/edit/${_id}`);
  }

  async refreshCampaigns() {
    const {
      getCampaigns,
      currentUserData: {
        data: { attributes }
      },
      impersonatedData
    } = this.props;

    const inImpersonationMode = !_isEmpty(impersonatedData);

    try {
      const userId = inImpersonationMode
        ? impersonatedData._id
        : attributes['custom:externalId'];
      await getCampaigns({ filter: JSON.stringify({ userId }) });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const {
      campaigns: {
        isFulfilled,
        data: { response }
      },
      impersonatedData
    } = this.props;

    const { data: campaigns } = response || { data: [] };

    const inImpersonationMode = !_isEmpty(impersonatedData);

    return (
      <Container className="d-flex justify-content-center">
        <Col style={{ width: '90vw' }}>
          <Row>
            <Button
              id="buttonAddCampaign"
              disabled={inImpersonationMode}
              onClick={e => this.handleClick(e)}
              outline
              color="secondary"
              className="col-12 mx-0 mt-4 pl-0 d-flex align-items-center justify-content-start"
            >
              <FontAwesomeIcon icon={faPlusCircle} className="mx-4" />
              <span>Add Campaign</span>
            </Button>
          </Row>
          {isFulfilled &&
            campaigns.map((campaign, index) => (
              <CampaignListItem
                key={campaign._id}
                index={index}
                inImpersonationMode={inImpersonationMode}
                campaign={campaign}
                toggleRunning={this.toggleRunning}
                redirectToEdit={this.redirectToEdit}
                handleDeleteCampaign={this.handleDeleteCampaign}
                createTestLead={this.createTestLead}
              />
            ))}
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  campaigns: state.campaign.find,
  tenant: state.tenant.findById,
  campaign: state.campaign.findById,
  currentUserData: state.auth.currentUser,
  impersonatedData: state.impersonation.data
});

const mapDispatchToProps = dispatch => ({
  getCampaigns: params => dispatch(getCampaignsAction(params)),
  createLead: params => dispatch(createProspectAction(params)),
  findCampaignById: id => dispatch(getCampaignAction(id)),
  editCampaign: (id, params) => dispatch(updateCampaignAction(id, params)),
  deleteCampaign: params => dispatch(deleteCampaignAction(params))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Campaigns);
