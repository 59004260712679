import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { Alert } from 'reactstrap';
import { SUCCESSFUL_PASSWORD_RESET_REDIRECT } from '../../store/constants';
import { completeNewPasswordAction } from '../../store/actions/auth';
import ChangePasswordForm from '../../components/Password/Change';

class ChangePassword extends Component {
  handleSubmitResetPassword = async values => {
    const {
      auth: {
        currentUser: { data: user }
      },
      completeNewPassword,
      history
    } = this.props;
    const { password } = values;

    await completeNewPassword(user, password);

    history.push(SUCCESSFUL_PASSWORD_RESET_REDIRECT);
  };

  render() {
    const {
      auth: {
        completeNewPassword: { isRejected, isPending, error: message = '' }
      }
    } = this.props;

    return (
      <div>
        {isRejected && message && <Alert color="danger">{message}</Alert>}
        <h1 className="d-none">Change Password</h1>
        <ChangePasswordForm
          onSubmit={this.handleSubmitResetPassword}
          isPending={isPending}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  completeNewPassword: (user, pwd) =>
    dispatch(completeNewPasswordAction(user, pwd))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChangePassword);
