import React, { Component } from 'react';
import { Container, Col } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UsersForm from '../../components/Users/UsersForm';
import { createUsersAction, getUserAction } from '../../store/actions/user';

import { ROLES } from '../../constants';

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.createUser = this.createUser.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    const {
      history,
      getUser,
      currentAuthUser: {
        data: { attributes }
      }
    } = this.props;

    try {
      const {
        value: {
          data: { role }
        }
      } = await getUser(attributes['custom:externalId']);

      if (role !== ROLES.OWNER) {
        history.push('/myTeam');
      }
    } catch (err) {
      console.log(err);
    }
  }

  createUser = async formData => {
    const {
      history,
      createUser,
      tenant: {
        data: {
          data: { _id: tenantId }
        }
      }
    } = this.props;

    const data = {
      tenantId,
      ...formData,
      role: ROLES.TEAM_MEMBER
    };

    try {
      await createUser(data);
      history.push('/myTeam');
    } catch (err) {
      console.log(err);
    }
  };

  onCancel = async () => {
    const { history } = this.props;

    history.push('/myTeam');
  };

  render() {
    const {
      currentAuthUser: { isFulfilled },
      user: createUser
    } = this.props;

    return (
      <Container className="d-flex justify-content-center">
        <Col style={{ width: '90vw' }}>
          {isFulfilled && (
            <UsersForm
              createUser={createUser}
              onSubmit={this.createUser}
              onCancel={this.onCancel}
            />
          )}
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  tenant: state.tenant.findById,
  currentAuthUser: state.auth.currentUser,
  user: state.user.create
});

const mapDispatchToProps = dispatch => ({
  getUser: id => dispatch(getUserAction(id)),
  createUser: params => dispatch(createUsersAction(params))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateUser);
