import React, { Component } from 'react';
import { Container, Col } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';

import CampaignForm from '../../components/Campaigns/CampaignForm';
import { createCampaignAction } from '../../store/actions/campaign';

class CreateCampaign extends Component {
  constructor(props) {
    super(props);

    this.createCampaign = this.createCampaign.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  createCampaign = async data => {
    const { createCampaign, history } = this.props;

    try {
      await createCampaign(data);
      history.push('/campaigns');
    } catch (err) {
      console.log(err);
    }
  };

  onCancel = async () => {
    const { history } = this.props;

    history.push('/campaigns');
  };

  render() {
    const {
      history,
      currentUser: { isFulfilled, data: user },
      impersonatedData
    } = this.props;

    if (!_isEmpty(impersonatedData)) history.push('/campaigns');

    return (
      <Container className="d-flex justify-content-center">
        <Col style={{ width: '90vw' }}>
          {isFulfilled && (
            <CampaignForm
              user={user}
              onSubmit={this.createCampaign}
              onCancel={this.onCancel}
            />
          )}
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  campaign: state.campaign.create,
  currentUser: state.auth.currentUser,
  impersonatedData: state.impersonation.data
});

const mapDispatchToProps = dispatch => ({
  createCampaign: params => dispatch(createCampaignAction(params))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CreateCampaign);
