import React, { Component } from 'react';
import { Row, Button, Col, FormGroup, Input } from 'reactstrap';
import {
  faPencilAlt,
  faCog,
  faTrashAlt,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../css/campaign-touchpoint.css';
import '../css/flyout.css';

class TouchPointInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
            showNameText: true,
            displayKeywords: false
        };

        this.toggleEditTouchpointName = this.toggleEditTouchpointName.bind(this);
        
        this.triggerFlyout = this.triggerFlyout.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.ref = React.createRef();
        
        this.keywordsOnFocus = this.keywordsOnFocus.bind(this);
    }

    focusSelect = (e) => {
        e.target.select();
    }


    triggerFlyout = () => {
        const {
            showMenu
        } = this.state;

        if (!showMenu) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        } 

        this.setState(prevState => ({
            showMenu: !prevState.showMenu,
        })); 
    };

    handleOutsideClick(e) {
        if (this.ref.current !== null) {
            return;
        }

        this.triggerFlyout();
    };

    keywordsOnFocus(){     
        this.setState(prevState => ({
            displayKeywords: !prevState.displayKeywords
        }));
    }

    toggleEditTouchpointName = () => {
        const { showNameText } = this.state;

        this.setState({ showNameText: !showNameText });
    };

    render() {
        const { showNameText, showMenu, displayKeywords } = this.state;
        const {
            data: {
                index,
                name,
                type,
                message,
                subject,
                intervalTime: { timeType, interval }
            },
            deleteTouchpoint,
            captureTouchpoint,
            previousTouchpointName
        } = this.props;

        return (
            <>
                {!!index ? 
                        <Row className="add-touchpoint flex-nowrap border border-secondary rounded p-0">
                            <Col className="col-auto text-muted d-flex align-items-center p-2 ">
                                Wait&nbsp;
                                <Input type="number" data-testcafe={`touchpoint-interval-${index}`} size="sm" name={`interval-${index}`} onFocus={(e) =>  this.focusSelect(e)} value={interval} min={1} max={99} onChange={captureTouchpoint} />
                                &nbsp;
                                <Input type="select" data-testcafe={`touchpoint-timeType-${index}`} size="sm" className="text-muted border border-muted" name={`timeType-${index}`} style={{minWidth:"100px"}} value={timeType} onChange={captureTouchpoint}>
                                    <option value="minutes">minute{interval !== 1 ? 's' : ''}</option>
                                    <option value="hours">hour{interval !== 1 ? 's' : ''}</option>
                                    <option value="days">day{interval !== 1 ? 's' : ''}</option>
                                </Input> &nbsp;Then
                            </Col>
                        </Row>
                    :
                        <></>
                }
                <Row className="campaign-touchpoint flex border border-secondary rounded my-4" ref={this.ref}>
                    <Col className="campaign-touchpoint-name-input-container col-auto border-secondary d-flex">
                        <div className="campaign-touchpoint-info text-muted d-flex flex-column justify-content-between">
                            <FormGroup>
                                {
                                    showNameText ? 
                                        <h3 className="campaign-touchpoint-name">{name}<Button color="link" data-testcafe={`touchpoint-edit-name-button-${index}`} onClick={this.toggleEditTouchpointName}><FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon></Button></h3>
                                    :
                                        <div className="d-flex mb-2"><Input type="text" data-testcafe={`touchpoint-name-input-${index}`} size="sm" name={`name-${index}`} placeholder="Touchpoint Name" value={name} onChange={captureTouchpoint} /><Button data-testcafe={`touchpoint-save-name-button-${index}`} onClick={this.toggleEditTouchpointName} color="link"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></Button></div>
                                }

                                <Input data-testcafe={`touchpoint-message-type-${index}`} className="text-muted border border-muted pt-2" size="sm" type="select" name={`type-${index}`} value={type} onChange={captureTouchpoint}>
                                    <option value="sms">Text Message</option>
                                    <option value="email">Email</option>
                                </Input>
                            </FormGroup>
                            <div className="mt-1">
                                <p>
                                    You can use prospect tags like &#123;firstname&#125; to build a more personalized message.
                                </p>
                                <p data-testcafe={`touchpoint-scheduled-message-${index}`}>
                                    Scheduled for: {index === 0 ? 'Immediately After Opt-in' : `${interval} ${interval === 1 ? timeType.replace('s', '') : timeType } After ${previousTouchpointName}`}
                                </p>
                            </div>
                        </div>
                        <Col onClick={() =>  this.triggerFlyout()} className="campaign-settings col-auto flyout-container border-left border-secondary justify-content-center align-items-center d-md-none">
                            <FontAwesomeIcon icon={faCog} className="cog text-secondary" />
                            { showMenu ?
                                <div className='flyout'>
                                    <Button disabled={index === 0} onClick={() => deleteTouchpoint(index)} color="link" className="flyout-link"><FontAwesomeIcon icon={faTrashAlt} className="mr-2"></FontAwesomeIcon>Delete {name}</Button>
                                </div>
                                : <></>
                            }
                        </Col>
                    </Col>
                    <Col className="campaign-touchpoint-template d-flex align-items-stretch p-0 d-flex flex-column">
                        {type === 'email' && <Input type="text" name={`subject-${index}`} onChange={captureTouchpoint} onFocus={this.keywordsOnFocus} value={subject} className="campaign-touchpoint-message-subject" placeholder="subject" />}
                        <Input data-testcafe={`touchpoint-message-${index}`} type="textarea" onFocus={this.keywordsOnFocus} className="campaign-touchpoint-message" name={`message-${index}`} onChange={captureTouchpoint} value={message} />
                        { displayKeywords ?
                            <div className="keywords">
                                Tags: 
                                First Name - &#123;firstname&#125; , Last Name - &#123;lastname&#125;, Full Name - &#123;fullname&#125;
                            </div> : null
                        }
                    </Col>
                    <Col onClick={() =>  this.triggerFlyout()} className="campaign-settings col-auto flyout-container border-left border-secondary justify-content-center align-items-center d-none d-md-flex">
                        <FontAwesomeIcon icon={faCog} className="cog text-secondary" />
                        { showMenu ?
                            <div className='flyout'>
                                <Button disabled={index === 0} onClick={() => deleteTouchpoint(index)} color="link" className="flyout-link"><FontAwesomeIcon icon={faTrashAlt} className="mr-2"></FontAwesomeIcon>Delete {name}</Button>
                            </div>
                            : <></>
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

export default TouchPointInput;
