import typeToReducer from 'type-to-reducer';
import { IMPERSONATION } from '../actions/types';

const DEFAULT_STATE = {};

export default typeToReducer(
  {
    [IMPERSONATION]: {
      START: (state, action) => ({
        ...action.payload
      }),
      END: () => DEFAULT_STATE
    }
  },
  DEFAULT_STATE
);
