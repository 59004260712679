import React, { Component } from 'react';
import { Row, Button, Input, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faCheck,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import TouchPointInput from '../touchPointInput';
import {
  MAX_INTERVAL_VALUE,
  TOUCHPOINT_DEFAULT_MESSAGE
} from '../../constants';

class CampaignForm extends Component {
  constructor(props) {
    super(props);

    const data = props.campaign
      ? props.campaign
      : {
          name: 'New Campaign',
          touchpoints: [
            {
              name: 'Touchpoint #1',
              type: 'sms',
              subject: '',
              message: TOUCHPOINT_DEFAULT_MESSAGE,
              intervalTime: {
                interval: 0,
                timeType: 'hours'
              }
            }
          ]
        };

    this.state = {
      name: data.name,
      showNameText: true,
      touchpoints: data.touchpoints
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.deleteTouchpoint = this.deleteTouchpoint.bind(this);
    this.captureTouchpoint = this.captureTouchpoint.bind(this);
    this.captureCampaignName = this.captureCampaignName.bind(this);
    this.createNewTouchpoint = this.createNewTouchpoint.bind(this);
  }

  createNewTouchpoint = () => {
    const { touchpoints } = this.state;

    const basicTouchpointData = {
      name: `Touchpoint #${touchpoints.length + 1}`,
      type: 'sms',
      subject: '',
      message: TOUCHPOINT_DEFAULT_MESSAGE,
      intervalTime: {
        interval: 1,
        timeType: 'hours'
      }
    };

    touchpoints.push(basicTouchpointData);

    this.setState({
      touchpoints
    });
  };

  deleteTouchpoint = i => {
    const { touchpoints } = this.state;

    this.setState({
      touchpoints: touchpoints.filter((touchpoint, index) => index !== i)
    });
  };

  captureTouchpoint = e => {
    const { name, value } = e.target;

    const { touchpoints } = this.state;

    const nameIndex = name.split('-');

    const validInterval = interval => {
      const parsedValue = parseInt(interval, 10);
      const MAX_VALUE = MAX_INTERVAL_VALUE;

      let intervalValue = parsedValue;

      if (!intervalValue || intervalValue < 0) {
        intervalValue = 1;
      }

      if (intervalValue > MAX_VALUE) {
        intervalValue = MAX_VALUE;
      }

      return intervalValue;
    };

    switch (nameIndex[0]) {
      case 'timeType':
        touchpoints[nameIndex[1]].intervalTime[nameIndex[0]] = value;
        break;
      case 'interval':
        touchpoints[nameIndex[1]].intervalTime[nameIndex[0]] = validInterval(
          value
        );
        break;
      default:
        touchpoints[nameIndex[1]][nameIndex[0]] = value;
        break;
    }

    this.setState({
      touchpoints
    });
  };

  editCampaignName = () => {
    this.setState(prevState => ({ showNameText: !prevState.showNameText }));
  };

  captureCampaignName = e => {
    const { value } = e.target;
    this.setState({ name: value });
  };

  onSubmit = async () => {
    const { name, touchpoints } = this.state;
    const { user, onSubmit } = this.props;

    await onSubmit({
      userId: user.attributes['custom:externalId'],
      running: true,
      name,
      touchpoints
    });
  };

  onCancel = async () => {
    const { onCancel } = this.props;

    await onCancel();
  };

  render() {
    const { name, showNameText, touchpoints } = this.state;

    return (
      <>
        <Row className="justify-content-between pt-2">
          {showNameText && (
            <h3 data-testcafe="campaign-name-text">
              {name}
              &nbsp;
              <Button
                color="link"
                data-testcafe="campaign-name-edit-button"
                onClick={() => this.editCampaignName()}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </h3>
          )}
          {!showNameText && (
            <div className="d-flex">
              <Input
                type="text"
                size="sm"
                name="name"
                style={{ minWidth: '270px' }}
                placeholder="Campaign Name"
                value={name}
                onChange={this.captureCampaignName}
                data-testcafe="campaign-name-input"
              />
              <Button
                color="link"
                data-testcafe="campaign-name-save-edit-button"
                onClick={this.editCampaignName}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </div>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex">
              <Button
                color="primary"
                className="m-1"
                type="submit"
                onClick={this.onSubmit}
              >
                Save Campaign
              </Button>
              <Button
                id="buttonCampaignCancel"
                color="secondary"
                className="m-1"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Row>
        <Row>
          <Col>
            {touchpoints.map((touchpoint, index) => {
              const previousTouchpointName =
                index && touchpoints[index - 1].name;

              return (
                <TouchPointInput
                  key={touchpoint._id}
                  data={{ index, ...touchpoint }}
                  deleteTouchpoint={this.deleteTouchpoint}
                  captureTouchpoint={this.captureTouchpoint}
                  previousTouchpointName={previousTouchpointName}
                />
              );
            })}
            {touchpoints.length < 5 ? (
              <Row className="mb-3">
                <Button
                  outline
                  color="secondary"
                  data-testcafe="touchpoint-add-button"
                  className="col-12 mx-0 mt-4 pl-0 d-flex align-items-center justify-content-start"
                  onClick={this.createNewTouchpoint}
                >
                  <FontAwesomeIcon icon={faPlusCircle} className="mx-4" />
                  <span>Add Touchpoint</span>
                </Button>
              </Row>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default CampaignForm;
