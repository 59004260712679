import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import { ROLES } from '../../constants';

class User extends Component {
  constructor(props) {
    super(props);

    this.startImpersonation = this.startImpersonation.bind(this);
  }

  startImpersonation = () => {
    const {
      user,
      startImpersonation,
      currentUser: { role }
    } = this.props;

    if (role === ROLES.OWNER) startImpersonation(user);
  };

  render() {
    const {
      index,
      isImpersonatedUser,
      user: { firstName, lastName },
      currentUser: { role }
    } = this.props;

    return (
      <Row
        className={`flex border border-${
          isImpersonatedUser ? 'danger' : 'secondary'
        } rounded my-4`}
      >
        <Col className="col-auto d-flex justify-content-center align-items-center">
          <FontAwesomeIcon icon={faUser} className="text-primary" />
        </Col>
        <Col className="pl-3">
          <Row className="py-3 d-flex align-items-center">
            <Col className="d-flex align-items-center">
              <p className="listItemName text-primary">
                {`${firstName} ${lastName}`}
              </p>
            </Col>
          </Row>
        </Col>
        {role === ROLES.OWNER ? (
          <Col className="col-auto d-flex justify-content-center align-items-center">
            {isImpersonatedUser ? (
              <></>
            ) : (
              <FontAwesomeIcon
                onClick={() => this.startImpersonation()}
                icon={faEye}
                className="text-secondary"
                data-testcafe={`team-member-impersonate-button-${index}`}
              />
            )}
          </Col>
        ) : (
          <></>
        )}
      </Row>
    );
  }
}

export default User;
