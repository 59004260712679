import React, { Component } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';

import UserListItem from '../../components/Users/UsersListItem';
import { getUsersAction, getUserAction } from '../../store/actions/user';
import { getResponseTimeAlertsAction } from '../../store/actions/responseTime';
import { startImpersonationAction } from '../../store/actions/impersonation';
import { ROLES } from '../../constants';

class Users extends Component {
  async componentDidMount() {
    const {
      getUsers,
      getUser,
      currentAuthUser: {
        data: { attributes }
      },
      tenant: {
        data: { data: tenant }
      }
    } = this.props;

    try {
      await getUser(attributes['custom:externalId']);
      await getUsers({
        filter: JSON.stringify({
          tenantId: tenant._id,
          role: ROLES.TEAM_MEMBER
        })
      });
    } catch (err) {
      console.log(err);
    }

    this.startImpersonation = this.startImpersonation.bind(this);
    this.renderAddUserButton = this.renderAddUserButton.bind(this);
  }

  startImpersonation = async user => {
    const { startImpersonation, getResponseTimes } = this.props;

    await startImpersonation(user);
    await getResponseTimes({ filter: { userId: user._id, endTime: null } });
  };

  impersonatedUser = (user, impersonated) => user._id === impersonated._id;

  removeTenantId = username => username.substring(username.indexOf('-') + 1);

  renderAddUserButton = () => {
    const {
      history,
      user: { isFulfilled: userDetailsIsFulfilled, data: userDetails },
      impersonatedData
    } = this.props;

    const inImpersonationMode = !_isEmpty(impersonatedData);

    let addUserBtn = <></>;

    if (
      !inImpersonationMode &&
      userDetailsIsFulfilled &&
      userDetails.data.role === ROLES.OWNER
    ) {
      addUserBtn = (
        <Button
          onClick={() => history.push('/myTeam/new')}
          outline
          color="secondary"
          className="col-12 mx-0 mt-4 pl-0 d-flex align-items-center justify-content-start"
          data-testcafe="team-member-create-button"
        >
          <FontAwesomeIcon icon={faPlusCircle} className="mx-4" />
          <span>Add Team Member</span>
        </Button>
      );
    }

    return addUserBtn;
  };

  render() {
    const {
      impersonatedData,
      users: {
        isFulfilled,
        data: { response }
      },
      user: { isFulfilled: userDetailsIsFulfilled, data: userDetails },
      currentAuthUser: {
        data: { username }
      }
    } = this.props;

    const { data: users } = response || { data: [] };
    const { data: currentUser } = userDetails || { data: [] };

    return (
      <Container className="d-flex justify-content-center">
        <Col style={{ width: '90vw' }}>
          <Row>{this.renderAddUserButton()}</Row>
          {isFulfilled &&
            userDetailsIsFulfilled &&
            users.map((user, index) => {
              const isCurrentUser =
                user.userName === this.removeTenantId(username);
              const isImpersonatedUser = this.impersonatedUser(
                user,
                impersonatedData
              );

              return isCurrentUser ? (
                <></>
              ) : (
                <UserListItem
                  index={index}
                  key={user._id}
                  user={user}
                  currentUser={currentUser}
                  isImpersonatedUser={isImpersonatedUser}
                  startImpersonation={this.startImpersonation}
                />
              );
            })}
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  users: state.user.find,
  user: state.user.findById,
  tenant: state.tenant.findById,
  currentAuthUser: state.auth.currentUser,
  impersonatedData: state.impersonation.data
});

const mapDispatchToProps = dispatch => ({
  getUser: id => dispatch(getUserAction(id)),
  getUsers: params => dispatch(getUsersAction(params)),
  startImpersonation: id => dispatch(startImpersonationAction(id)),
  getResponseTimes: params => dispatch(getResponseTimeAlertsAction(params))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Users);
