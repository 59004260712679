import React, { Component } from 'react';
import { Badge } from 'reactstrap';

class ProspectAlertBadge extends Component {
  removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  };

  render() {
    const { responseTimes } = this.props;

    const uniqueUnrespondedProspects = this.removeDuplicates(
      responseTimes,
      'prospectId'
    );

    return (
      <>
        <Badge
          color="danger"
          style={{
            position: 'relative',
            top: '-14px',
            left: '-12px',
            borderRadius: '50px',
            padding: '0.5em 0.7em',
            fontWeight: 'normal'
          }}
        >
          {!!uniqueUnrespondedProspects.length &&
            uniqueUnrespondedProspects.length}
        </Badge>
      </>
    );
  }
}

export default ProspectAlertBadge;
