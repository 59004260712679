import React, { Component } from 'react';
import { Col, Row, Alert, Button, Form, FormGroup, Input } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { signInAction, getAuthenticatedUser } from '../store/actions/auth';
import { getUsersAction, updateUserAction } from '../store/actions/user';
import withLoader from '../components/HOC/withLoader';
import '../css/login.css';
import {
  DEFAULT_BACKGROUND_IMAGE,
  DEFAULT_LOGO_IMAGE,
  LOGGED_IN_REDIRECT_URL,
  CHANGE_PASSWORD_REDIRECT_URL,
  RESET_PASSWORD_REDIRECT_URL
} from '../constants';

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      email: '',
      password: ''
    };
  }

  updateUserLastLogin = async () => {
    try {
      const { email } = this.state;
      const {
        getUsers,
        updateUser,
        tenant: {
          findById: {
            data: { data: tenant }
          }
        }
      } = this.props;

      const {
        value: {
          response: { data }
        }
      } = await getUsers({
        filter: {
          tenantId: tenant._id,
          email
        }
      });

      if (data.length) {
        const user = data[0];

        await updateUser(user._id, {
          lastLogin: new Date()
        });
      } else {
        throw new Error('User was not found');
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  handleSubmit = async () => {
    const { password, email } = this.state;
    const { signIn, history, getAuthUser } = this.props;

    try {
      const result = await signIn({
        username: email,
        password
      });

      if (
        result.value &&
        result.value.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        history.push(CHANGE_PASSWORD_REDIRECT_URL);
      } else {
        await getAuthUser();
        await this.updateUserLastLogin();
        history.push(LOGGED_IN_REDIRECT_URL);
      }
    } catch (err) {
      if (err.code === 'PasswordResetRequiredException') {
        history.push(RESET_PASSWORD_REDIRECT_URL);
      } else {
        throw err;
      }
    }
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  render() {
    const {
      currentUser: { isRejected, error: { message } = {} },
      tenant: {
        find: {
          isFulfilled: tenantsIsFulfilled,
          data: { response: tenantData }
        },
        findById: {
          data: { data: tenant }
        }
      }
    } = this.props;

    const background = tenant.backgroundUrl || DEFAULT_BACKGROUND_IMAGE;
    const logo = tenant.logoUrl || DEFAULT_LOGO_IMAGE;
    const pageStyle = tenant.backgroundColor
      ? { backgroundColor: tenant.backgroundColor }
      : {};

    const { data: tenants } = tenantData || { data: [] };

    return (
      <Col id="loginPage" style={pageStyle}>
        <div
          id="loginContainer"
          style={{ backgroundImage: `url(${background})` }}
        >
          <img id="loginLogo" alt="Main Logo" src={logo} />
          <Form id="loginForm" onKeyDown={e => this.handleKeyDown(e)}>
            {isRejected && message && (
              <Alert color="danger" style={{ width: '100%', zIndex: 100 }}>
                {`Login failed: ${message}`}
              </Alert>
            )}
            {!tenant._id &&
              process.env.REACT_APP_ENV === 'development' &&
              tenantsIsFulfilled && (
                <FormGroup>
                  <Input
                    id="tenantId"
                    name="tenantId"
                    placeholder="Tenant"
                    onChange={this.handleChange}
                    type="select"
                    data-testcafe="login-tenant-select"
                  >
                    <option value="default">
                      -- No Tenant (For Admin User) --
                    </option>
                    {tenants.map(t => (
                      <option key={t._id} value={t._id}>
                        {t.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              )}
            <FormGroup>
              <Input
                id="email"
                name="email"
                placeholder="Email"
                onChange={this.handleChange}
                type="text"
                data-testcafe="login-username-email-input"
              />
            </FormGroup>
            <FormGroup>
              <Input
                id="password"
                name="password"
                placeholder="Password"
                onChange={this.handleChange}
                type="password"
                data-testcafe="login-password-input"
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col className="loginButtonContainer">
                  <Button
                    id="loginBtn"
                    onClick={this.handleSubmit}
                    data-testcafe="login-button"
                  >
                    Sign In
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </div>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  tenant: state.tenant
});

const mapDispatchToProps = dispatch => ({
  signIn: credentials => dispatch(signInAction(credentials)),
  getAuthUser: () => dispatch(getAuthenticatedUser()),
  getUsers: params => dispatch(getUsersAction(params)),
  updateUser: (id, params) => dispatch(updateUserAction(id, params))
});

export default compose(
  withLoader,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Login);
