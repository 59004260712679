import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown
} from '@fortawesome/free-solid-svg-icons';
import { STATUS } from '../../constants';

import { getProspectsAction } from '../../store/actions/prospect';
import { getCampaignsAction } from '../../store/actions/campaign';
import { getResponseTimesAction } from '../../store/actions/responseTime';

import ProspectListItem from '../../components/Prospects/ProspectListItem';

import '../../css/prospects.css';

class Prospects extends Component {
  constructor(props) {
    super(props);

    this.getProspects = this.getProspects.bind(this);
  }

  async componentDidMount() {
    await this.refresh();
  }

  getProspects = async sort => {
    const {
      getProspects,
      getCampaigns,
      getResponseTimes,
      prospects: { data: response },
      currentUserData: {
        data: { attributes }
      },
      impersonatedData
    } = this.props;

    const inImpersonationMode = !_isEmpty(impersonatedData);

    const userId = inImpersonationMode
      ? impersonatedData._id
      : attributes['custom:externalId'];

    const { params } = response.params
      ? response
      : { params: { sort: '-createdAt' } };

    const {
      value: { response: prospectsResponse }
    } = await getProspects({
      ordering: sort || params.sort,
      filter: JSON.stringify({ userId, status: STATUS.PROSPECT })
    });

    const { data: prospects } = prospectsResponse || { data: [] };

    const campaignIds = prospects.length
      ? prospects.map(prospect => prospect.campaignId)
      : prospects;
    const prospectIds = prospects.length
      ? prospects.map(prospect => prospect._id)
      : prospects;

    await getCampaigns({ filter: { campaignIds } });
    await getResponseTimes({ filter: { prospectIds } });
  };

  async refresh() {
    try {
      await this.getProspects();
    } catch (err) {
      console.log(err);
    }
  }

  renderSortIcon(order = 'createdAt', sortValue, sortText) {
    const isDescending = order.includes('-');
    const orderValue = order.replace('-', '');

    let icon = faSort;
    let sortOrder = sortValue;

    if (orderValue === sortValue) {
      icon = isDescending ? faSortDown : faSortUp;
      sortOrder = isDescending ? sortValue : `-${sortValue}`;
    }

    return (
      <>
        <Col className="pl-3">
          <Row className="py-3 d-flex align-items-center">
            <Col className="d-flex align-items-center">
              <p
                className="listItemName text-secondary"
                onClick={() => this.getProspects(sortOrder)}
              >
                {`${sortText} `}
                <FontAwesomeIcon
                  data-testcafe={`sort-${sortValue}-icon`}
                  icon={icon}
                />
              </p>
            </Col>
          </Row>
        </Col>
      </>
    );
  }

  render() {
    const {
      prospects: {
        isFulfilled,
        data: { response },
        data: { params }
      },
      campaigns: {
        isFulfilled: campaignIsFulfilled,
        data: { response: campaignResponse }
      },
      responseTimes: {
        isFulfilled: responseTimeIsFulfilled,
        data: { response: responseTimeResponse }
      }
    } = this.props;

    const { data: prospects } = response || { data: [] };
    const { ordering } = params || { ordering: '-createdAt' };
    const { data: campaigns } = campaignResponse || { data: [] };
    const { data: responseTimes } = responseTimeResponse || { data: [] };

    return (
      <Container className="d-flex justify-content-center">
        <Col style={{ width: '90vw' }}>
          {isFulfilled && campaignIsFulfilled && (
            <Row className="flex rounded">
              {this.renderSortIcon(ordering, 'firstName', 'Fullname')}
              <Col className="pl-3">
                <Row className="py-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <p className="listItemName text-secondary">Campaign</p>
                  </div>
                </Row>
              </Col>
              <Col className="pl-3">
                <Row className="py-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <p className="listItemName text-secondary">Last Response</p>
                  </div>
                </Row>
              </Col>
              <Col className="pl-3">
                <Row className="py-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <p className="listItemName text-secondary">Response Time</p>
                  </div>
                </Row>
              </Col>
            </Row>
          )}
          {isFulfilled &&
            campaignIsFulfilled &&
            responseTimeIsFulfilled &&
            prospects.map((prospect, index) => (
              <ProspectListItem
                index={index}
                prospect={prospect}
                campaigns={campaigns}
                responseTimes={responseTimes}
              />
            ))}
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  prospects: state.prospect.find,
  campaigns: state.campaign.find,
  responseTimes: state.responseTime.find,
  currentUserData: state.auth.currentUser,
  impersonatedData: state.impersonation.data
});

const mapDispatchToProps = dispatch => ({
  getProspects: params => dispatch(getProspectsAction(params)),
  getCampaigns: params => dispatch(getCampaignsAction(params)),
  getResponseTimes: params => dispatch(getResponseTimesAction(params))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Prospects);
