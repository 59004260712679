import api from '../../api';

import { TENANT_FINDBYID, TENANT_FIND } from './types';

export const getTenantsAction = params => ({
  type: TENANT_FIND,
  payload: api.tenant.find(params)
});

export const getTenantAction = id => ({
  type: TENANT_FINDBYID,
  payload: api.tenant.findById(id)
});
