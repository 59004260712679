import React from 'react';

import moment from 'moment';
import 'moment-duration-format';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { MOMENT_FORMATS } from '../../constants';

const ProspectListItem = props => {
  const formatResponseTime = (startTime, endTime) => {
    let responseTime = 'Not Responded Yet';

    if (endTime) {
      const startMoment = moment(startTime);
      const endMoment = moment(endTime);
      const diff = endMoment.diff(startMoment);
      const duration = moment.duration(diff, 'milliseconds');

      responseTime = duration.format(MOMENT_FORMATS.TIME_ELAPSED, {
        useSignificantDigits: true,
        precision: 2,
        trunc: true
      });
    }

    return responseTime;
  };

  const { prospect, campaigns, responseTimes, index } = props;

  const prospectsCampaign = campaigns.filter(
    campaign => campaign._id === prospect.campaignId
  );
  const prospectsResponseTimes = responseTimes.filter(
    responseTime => responseTime.prospectId === prospect._id
  );

  const { name: campaignName } = prospectsCampaign[0] || {
    name: 'Unavailable'
  };
  const {
    responseTime: { startTime, endTime }
  } = prospectsResponseTimes[0] || {
    responseTime: { startTime: '', endTime: '' }
  };

  const lastResponse =
    (startTime && moment(startTime).format(MOMENT_FORMATS.MONTH_DAY)) ||
    'Unavailable';

  const responseTime = formatResponseTime(startTime, endTime);

  return (
    <Row
      className={`flex border border-${
        responseTime === 'Not Responded Yet' ? 'danger' : 'secondary'
      } rounded mb-4`}
    >
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">
              {`${prospect.firstName} ${prospect.lastName}`}
            </p>
          </Col>
        </Row>
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">{campaignName}</p>
          </Col>
        </Row>
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">{lastResponse}</p>
          </Col>
        </Row>
      </Col>
      <Col className="pl-3">
        <Row className="py-3 d-flex align-items-center">
          <Col className="d-flex align-items-center">
            <p className="listItemName text-primary">{responseTime}</p>
          </Col>
        </Row>
      </Col>
      <Col className="col-auto d-flex justify-content-center align-items-center">
        <Link
          to={`/prospects/${prospect._id}`}
          data-testcafe={`prospect-detail-link-${index}`}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </Col>
    </Row>
  );
};

export default ProspectListItem;
