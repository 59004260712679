export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_CURRENT_USER = 'AUTH_CURRENT_USER';
export const AUTH_CURRENT_USER_RESET = 'AUTH_CURRENT_USER_RESET';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_COMPLETE_NEW_PASSWORD = 'AUTH_COMPLETE_NEW_PASSWORD';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_CONFIRM = 'AUTH_FORGOT_PASSWORD_CONFIRM';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';

export const TENANT_FIND = 'TENANT_FIND';
export const TENANT_FINDBYID = 'TENANT_FINDBYID';

export const USER_FIND = 'USER_FIND';
export const USER_FINDBYID = 'USER_FINDBYID';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';

export const CAMPAIGN_FIND = 'CAMPAIGN_FIND';
export const CAMPAIGN_FINDBYID = 'CAMPAIGN_FINDBYID';
export const CAMPAIGN_CREATE = 'CAMPAIGN_CREATE';
export const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE';
export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE';

export const PROSPECT_FIND = 'PROSPECT_FIND';
export const PROSPECT_FINDBYID = 'PROSPECT_FINDBYID';
export const PROSPECT_CREATE = 'PROSPECT_CREATE';

export const MESSAGE_FIND = 'MESSAGE_FIND';
export const MESSAGE_FINDBYID = 'MESSAGE_FINDBYID';
export const MESSAGE_CREATE = 'MESSAGE_CREATE';

export const RESPONSE_TIME_FIND = 'RESPONSE_TIME_FIND';
export const RESPONSE_TIME_ALERT = 'RESPONSE_TIME_ALERT';

export const IMPERSONATION = 'IMPERSONATION';
export const IMPERSONATION_END = 'IMPERSONATION_END';
export const IMPERSONATION_START = 'IMPERSONATION_START';
