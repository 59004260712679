import api from '../../api';
import {
  AUTH_SIGN_IN,
  AUTH_CURRENT_USER,
  AUTH_LOGOUT,
  AUTH_CURRENT_USER_RESET,
  AUTH_COMPLETE_NEW_PASSWORD,
  AUTH_FORGOT_PASSWORD,
  AUTH_FORGOT_PASSWORD_CONFIRM,
  AUTH_CHANGE_PASSWORD
} from './types';

export const signInAction = credentials => ({
  type: AUTH_SIGN_IN,
  payload: api.auth.signIn(credentials)
});

export const completeNewPasswordAction = (user, password) => ({
  type: AUTH_COMPLETE_NEW_PASSWORD,
  payload: api.auth.completeNewPassword(user, password)
});

export const getAuthenticatedUser = () => ({
  type: AUTH_CURRENT_USER,
  payload: api.auth.currentUser()
});

export const forgotPasswordAction = username => ({
  type: AUTH_FORGOT_PASSWORD,
  payload: api.auth.forgotPassword(username)
});

export const forgotPasswordConfirmAction = params => ({
  type: AUTH_FORGOT_PASSWORD_CONFIRM,
  payload: api.auth.forgotPasswordConfirm(params)
});

export const logout = () => ({
  type: AUTH_LOGOUT,
  payload: api.auth.logout()
});

export const resetCurrentUser = () => ({
  type: AUTH_CURRENT_USER_RESET
});

export const changePasswordAction = (
  cognitoUser,
  oldPassword,
  newPassword
) => ({
  type: AUTH_CHANGE_PASSWORD,
  payload: api.auth.changePassword(cognitoUser, oldPassword, newPassword)
});
