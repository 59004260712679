import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { Alert } from 'reactstrap';
import { SUCCESSFUL_PASSWORD_RESET_REDIRECT } from '../../store/constants';
import {
  forgotPasswordAction,
  forgotPasswordConfirmAction
} from '../../store/actions/auth';

import ForgotPasswordForm from '../../components/Password/Forgot';
import UpdatePasswordForm from '../../components/Password/Update';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ''
    };
  }

  handleSubmitForgotPassword = async values => {
    const { forgotPassword } = this.props;
    const { email } = values;
    this.setState({ email });

    await forgotPassword(email);
  };

  handleSubmitResetPassword = async values => {
    const { forgotPasswordConfirm, history } = this.props;
    const { email } = this.state;
    const { code, newPassword } = values;

    await forgotPasswordConfirm({
      username: email,
      code: `${code}`,
      newPassword
    });

    history.push(SUCCESSFUL_PASSWORD_RESET_REDIRECT);
  };

  renderForgotPassword = () => {
    const {
      auth: {
        forgotPassword: { isRejected, isPending, error: { message } = {} }
      }
    } = this.props;

    return (
      <div>
        {isRejected && message && <Alert color="danger">{message}</Alert>}
        <h1 className="d-none">Forgot Password?</h1>
        <ForgotPasswordForm
          onSubmit={this.handleSubmitForgotPassword}
          isPending={isPending}
        />
      </div>
    );
  };

  renderUpdatePassword = () => {
    const {
      auth: {
        forgotPassword: { isRejected, isPending, error: { message } = {} }
      }
    } = this.props;

    return (
      <div>
        {isRejected && message && <Alert color="danger">{message}</Alert>}
        <h1 className="d-none">Update Password</h1>
        <UpdatePasswordForm
          onSubmit={this.handleSubmitResetPassword}
          isPending={isPending}
        />
      </div>
    );
  };

  render() {
    const {
      auth: {
        forgotPassword: { isFulfilled }
      }
    } = this.props;

    return !isFulfilled
      ? this.renderForgotPassword()
      : this.renderUpdatePassword();
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: username => dispatch(forgotPasswordAction(username)),
  forgotPasswordConfirm: params => dispatch(forgotPasswordConfirmAction(params))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ForgotPassword);
