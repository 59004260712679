import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEnvelope, faExpand } from '@fortawesome/free-solid-svg-icons';
import {
    Modal,
    ModalBody,
    ModalHeader,
  } from 'reactstrap';

import '../css/prospect.css';
import { MESSAGE_STATUS, MESSAGE_TYPE, ROLES, STATUS, EXPAND_AT, ELLIPSIZE_AT } from '../constants';

class Message extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isOpen: false
      };

      this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        const { isOpen } = this.state;

        this.setState({ isOpen: !isOpen });
    };

    getEllipsizedBody = (body) => {
        let displayBody = body;

        if (body.length > EXPAND_AT) {
            const ellipsizedBody = `${body.substring(0, ELLIPSIZE_AT)} …`

            displayBody = ellipsizedBody;
        }
        
        return displayBody;
    }

    render() {
        const { message: { messageType, body, messageStatus }, displayDate } = this.props;
        const { isOpen } = this.state;
        
        const TeamMember = ROLES.TEAM_MEMBER.replace(' ', '');
        const Prospect = STATUS.PROSPECT;

        const perspective = messageStatus === MESSAGE_STATUS.RECEIVED ? Prospect : TeamMember;

        const displayedBody = this.getEllipsizedBody(body);

        return (
            <>
                <div className={'col-sm-12 col-md-7 col-lg-6  msgContainer' + perspective}>
                    <div className={"date" + perspective}>{ displayDate }</div>
                    <div className={"message " + perspective }>
                        {  perspective === TeamMember ? <div className="messageTypeContainer">
                            { messageType === MESSAGE_TYPE.SMS ? <FontAwesomeIcon icon={faComment}></FontAwesomeIcon>  :  <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>}
                        </div> : false }
                        
                        <div className="content">
                            { perspective === Prospect && body.length > EXPAND_AT && <div
                                style={{
                                    marginRight: '20px'
                                }}
                            ><FontAwesomeIcon icon={faExpand} onClick={this.toggle}></FontAwesomeIcon></div>}
                            { !!body ? <div style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxHeight: '150px'
                            }}>{displayedBody}</div> : false }
                            { perspective !== Prospect && body.length > EXPAND_AT && <div
                                style={{
                                    marginLeft: '20px'
                                }}
                            ><FontAwesomeIcon icon={faExpand} onClick={this.toggle}></FontAwesomeIcon></div>}
                        </div>
                        
                        {  perspective === Prospect ? <div className="messageTypeContainer">
                            { messageType === MESSAGE_TYPE.SMS ? <FontAwesomeIcon icon={faComment}></FontAwesomeIcon>  :  <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>}
                        </div> : false }
                    </div>
                </div>
                <Modal
                    centered
                    isOpen={isOpen}
                    toggle={this.toggle}
                    style={{ minWidth: '50vw' }}
                    >
                    <ModalHeader toggle={this.toggle} />
                    <ModalBody style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                        {body}
                    </ModalBody>
                </Modal>
            </>
        );
    }
};

export default Message;
