import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faReply,
  faEnvelope,
  faPhoneAlt,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

import Message from '../../components/message';
import SendSMSModal from '../../components/Prospects/SendSMSModal';
import SendEmailModal from '../../components/Prospects/SendEmailModal';

import { getProspectAction } from '../../store/actions/prospect';
import {
  getMessagesAction,
  createMessageAction
} from '../../store/actions/message';

import '../../css/prospect.css';
import { MESSAGE_STATUS } from '../../constants';

class Lead extends Component {
  constructor(props) {
    super(props);

    this.sendMessage = this.sendMessage.bind(this);
    this.getFirstReceivedMessageDate = this.getFirstReceivedMessageDate.bind(
      this
    );
  }

  async componentDidMount() {
    await this.refresh();
  }

  getFirstReceivedMessageDate = () => {
    const {
      messages: {
        data: {
          response: { data: messages }
        }
      }
    } = this.props;

    const receivedMessages = messages.filter(
      message => message.messageStatus === MESSAGE_STATUS.RECEIVED
    );

    let respondedDate = "Hasn't Responded";

    if (receivedMessages.length) {
      const firstResponse = receivedMessages[0];

      respondedDate = this.formatDate(firstResponse.timestamp);
    }

    return respondedDate;
  };

  sendMessage = async messageData => {
    const {
      match: {
        params: { id: prospectId }
      },
      createMessage
    } = this.props;

    await createMessage({
      ...messageData,
      prospectId,
      messageStatus: MESSAGE_STATUS.OUTGOING
    });
    await this.refresh();
  };

  formatDate = date => moment(date).format('MMM do, h:mm A');

  async refresh() {
    try {
      const {
        match: {
          params: { id: leadId }
        },
        getLead,
        getMessages
      } = this.props;

      await getLead(leadId);
      await getMessages({
        filter: { prospectId: leadId },
        ordering: 'timestamp'
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const {
      lead: {
        isFulfilled: leadIsFulfilled,
        data: { data: leadDetails }
      },
      messages: {
        isFulfilled: messagesIsFulfilled,
        data: { response: messages }
      },
      impersonatedData
    } = this.props;

    const inImpersonationMode = !_isEmpty(impersonatedData);

    return (
      <div id="Prospect" className="prospectPage">
        <div className="header d-flex">
          {leadIsFulfilled && (
            <>
              <div className="d-flex">
                <FontAwesomeIcon
                  className="prospectHeaderIcon"
                  icon={faUser}
                  size="3x"
                />
                <div className="d-flex flex-column prospectHeaderDetails">
                  <div>
                    <FontAwesomeIcon icon={faEnvelope} size="sm" />
                    &nbsp;
                    {leadDetails.email}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faPhoneAlt} size="sm" />
                    &nbsp;
                    {leadDetails.phoneNumber}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column">
                <div className="mb-2">
                  Entered Campaign: &nbsp;
                  <span className="date">
                    <FontAwesomeIcon icon={faSignInAlt} />
                    &nbsp;
                    {this.formatDate(leadDetails.createdAt)}
                  </span>
                </div>
                {messagesIsFulfilled && (
                  <div>
                    Responded to Campaign: &nbsp;
                    <span className="date">
                      <FontAwesomeIcon icon={faSignOutAlt} />
                      &nbsp;
                      {this.getFirstReceivedMessageDate()}
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {messagesIsFulfilled && (
          <div className="messages">
            {messages.data.map(message => (
              <Message
                message={message}
                displayDate={this.formatDate(message.timestamp)}
              />
            ))}
          </div>
        )}

        <div className="footer">
          <SendEmailModal
            sendMessage={this.sendMessage}
            disabled={inImpersonationMode}
          />
          <SendSMSModal
            sendMessage={this.sendMessage}
            disabled={inImpersonationMode}
          />
          <Button
            className="m-2"
            style={{
              background: 'transparent',
              border: 'none'
            }}
          >
            <Link to="/leads">
              <FontAwesomeIcon icon={faReply} size="lg" />
            </Link>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  messages: state.message.find,
  lead: state.prospect.findById,
  impersonatedData: state.impersonation.data
});

const mapDispatchToProps = dispatch => ({
  getLead: id => dispatch(getProspectAction(id)),
  getMessages: params => dispatch(getMessagesAction(params)),
  createMessage: params => dispatch(createMessageAction(params))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Lead);
