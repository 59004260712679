import { combineReducers } from 'redux';

import authCurrentUser from './authCurrentUser';
import authLogout from './authLogout';
import authPasswordReducer from './authPassword';
import authCompleteNewPassword from './authCompleteNewPassword';
import authChangePassword from './authChangePassword';

import tenantFind from './tenantFind';
import tenantFindById from './tenantFindById';

import userFind from './userFind';
import userFindById from './userFindById';
import userCreate from './userCreate';
import userUpdate from './userUpdate';

import campaignFind from './campaignFind';
import campaignFindById from './campaignFindById';
import campaignCreate from './campaignCreate';
import campaignUpdate from './campaignUpdate';
import campaignDelete from './campaignDelete';

import prospectFind from './prospectFind';
import prospectFindById from './prospectFindById';
import prospectCreate from './prospectCreate';

import messageFind from './messageFind';
import messageFindById from './messageFindById';
import messageCreate from './messageCreate';

import responseTimeFind from './responseTimeFind';
import responseTimeAlert from './responseTimeAlert';

import impersonationToggle from './impersonationToggle';

export default combineReducers({
  impersonation: combineReducers({
    data: impersonationToggle
  }),
  auth: combineReducers({
    currentUser: authCurrentUser,
    logout: authLogout,
    completeNewPassword: authCompleteNewPassword,
    forgotPassword: authPasswordReducer,
    changePassword: authChangePassword
  }),
  tenant: combineReducers({
    find: tenantFind,
    findById: tenantFindById
  }),
  user: combineReducers({
    find: userFind,
    findById: userFindById,
    create: userCreate,
    update: userUpdate
  }),
  campaign: combineReducers({
    find: campaignFind,
    findById: campaignFindById,
    create: campaignCreate,
    update: campaignUpdate,
    delete: campaignDelete
  }),
  prospect: combineReducers({
    find: prospectFind,
    findById: prospectFindById,
    create: prospectCreate
  }),
  message: combineReducers({
    find: messageFind,
    findById: messageFindById,
    create: messageCreate
  }),
  responseTime: combineReducers({
    find: responseTimeFind,
    alert: responseTimeAlert
  })
});
