import React, { PureComponent } from 'react';
import { NavLink as RouteNavLink } from 'react-router-dom';
import {
  Label,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { connect } from 'react-redux';
import _isEmpty from 'lodash.isempty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faCog, faTimes } from '@fortawesome/free-solid-svg-icons';

import { getResponseTimeAlertsAction } from '../store/actions/responseTime';
import { endImpersonationAction } from '../store/actions/impersonation';
import { DEFAULT_LOGO_IMAGE } from '../constants';

import ProspectAlertBadge from './Prospects/ProspectsAlertBadge';

import '../css/header.css';

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isMenuToggleOpen: false,
      active: false
    };

    this.menuToggle = this.menuToggle.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  async componentDidMount() {
    await this.refresh();
  }

  // eslint-disable-next-line class-methods-use-this
  getFriendlyUserName (user) {
    if (user.attributes && user.attributes.given_name) {
      return user.attributes.given_name;
    }

    const parts = user.username.split('-');
    return parts.pop();
  }

  menuToggle () {
    const { isMenuToggleOpen, active } = this.state;
    this.setState({
      isMenuToggleOpen: !isMenuToggleOpen,
      active: !active
    });
  }

  async handleSignOut () {
    const {
      handleSignOut,
      endImpersonation
    } = this.props;

    await endImpersonation();
    await handleSignOut();
  }

  async refresh () {
    const {
      auth: {
        currentUser: { 
          data: {
            attributes
          } 
        }
      },
      getResponseTimes
    } = this.props;

    const userId = attributes['custom:externalId'];

    await getResponseTimes({ filter: { userId, endTime: null } });
  }

  render() {
    const {
      tenant: {
        findById: {
          data: { data: tenant }
        }
      },
      auth: {
        currentUser: { data: userData }
      },
      responseTimes: {
        isFulfilled,
        data: responseTimes
      },
      impersonatedData,
      handleEndImpersonation
    } = this.props;

    const logo = tenant.logoUrl || DEFAULT_LOGO_IMAGE;

    const friendlyName = this.getFriendlyUserName(userData);

    const inImpersonationMode = !_isEmpty(impersonatedData);

    const renderedName = inImpersonationMode
      ? impersonatedData.userName
      : friendlyName;

    return (
      <>
        <Nav className="d-flex justify-content-between flex-nowrap" tabs>
          <div id="whiteLabel">
            <img id="whiteLabelImage" alt="Main Logo" src={logo} />
          </div>
            <div className="d-flex flex-direction-row align-items-end">
              <NavItem>
                <NavLink
                  id="navTabCampaigns"
                  className="text-center"
                  to="/campaigns"
                  activeClassName="active"
                  tag={RouteNavLink}
                >
                  Campaigns
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  id="navTabLeads"
                  className="text-center"
                  to="/leads"
                  activeClassName="active"
                  tag={RouteNavLink}
                  data-testcafe="navigation-leads-tab"
                >
                  Leads
                </NavLink>
              </NavItem>
              <NavItem className="d-flex align-items-center">
                <NavLink
                  id="navTabProspects"
                  className="text-center"
                  to="/prospects"
                  activeClassName="active"
                  tag={RouteNavLink}
                  data-testcafe="navigation-prospects-tab"
                >
                  Prospects
                </NavLink>
                {isFulfilled && <ProspectAlertBadge responseTimes={responseTimes.response.data} />}
              </NavItem>
              <NavItem>
                <NavLink
                  id="navTabMyTeam"
                  className="text-center"
                  to="/myTeam"
                  activeClassName="active"
                  tag={RouteNavLink}
                  data-testcafe="navigation-myteam-tab"
                >
                  My Team
                </NavLink>
              </NavItem>
            </div>
          <div className="d-flex">
            <div className="d-flex align-items-center justify-content-end">
              {inImpersonationMode ? (
                <div className="d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    id="viewAsEndBtn"
                    className="bg-danger text-white rounded mx-1"
                    icon={faTimes}
                    onClick={async () => {
                      await handleEndImpersonation()
                      await this.refresh()
                    }}
                    data-testcafe="navigation-end-impersonation-button"
                  />
                  <Label className="bg-danger text-white m-0 rounded px-1">
                    Viewing As:
                  </Label>
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-end align-items-end">
                <FontAwesomeIcon
                  color="var(--primary-color)"
                  className="m-2 justify-content-end"
                  icon={faUserAlt}
                />
                <span className="mb-1">{renderedName}</span>
                <UncontrolledDropdown className="d-none d-flex">
                  <DropdownToggle nav className="border-0">
                    <FontAwesomeIcon
                      color="#6c757d"
                      size="lg"
                      icon={faCog}
                      id="gearIcon"
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink href="#" onClick={this.handleSignOut}>
                        {`Logout (${friendlyName})`}
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </Nav>
      </>
    );
  }
}

const mapStateToProps = state => ({
    auth: state.auth,
    tenant: state.tenant,
    responseTimes: state.responseTime.alert,
    impersonatedData: state.impersonation.data
});

const mapDispatchToProps = dispatch => ({
  endImpersonation: () => dispatch(endImpersonationAction()),
  getResponseTimes: params => dispatch(getResponseTimeAlertsAction(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
