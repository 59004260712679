import { IMPERSONATION_END, IMPERSONATION_START } from './types';

export const startImpersonationAction = impersonatedData => ({
  type: IMPERSONATION_START,
  payload: impersonatedData
});

export const endImpersonationAction = () => ({
  type: IMPERSONATION_END
});
