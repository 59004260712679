import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';

import {
  logout,
  resetCurrentUser,
  getAuthenticatedUser
} from '../../store/actions/auth';
import { endImpersonationAction } from '../../store/actions/impersonation';
import {
  LOGGED_OUT_REDIRECT_URL,
  NON_AUTHORIZED_REDIRECT_URL
} from '../../constants';

import Header from '../header';
import Lead from '../../views/Leads/Lead';
import Leads from '../../views/Leads/Leads';
import Users from '../../views/Users/Users';
import CreateUsers from '../../views/Users/CreateUser';
import Prospect from '../../views/Prospects/Prospect';
import Prospects from '../../views/Prospects/Prospects';
import Campaigns from '../../views/Campaigns/Campaigns';
import EditCampaign from '../../views/Campaigns/EditCampaign';
import CreateCampaign from '../../views/Campaigns/CreateCampaign';

import '../../css/list.css';
import '../../css/view-as.css';

class DefaultLayout extends Component {
  componentDidMount = async () => {
    const {
      getAuthUser,
      auth: {
        currentUser: { data: currentUserData }
      }
    } = this.props;

    try {
      if (_isEmpty(currentUserData)) {
        await getAuthUser();
      }
    } catch (err) {
      // do nothing for now. Just falls back to
      // normal functionality.
      console.log(err);
    }
  };

  signOut = async () => {
    const { signOut, history } = this.props;

    await signOut();
    history.push(LOGGED_OUT_REDIRECT_URL);
  };

  handleEndImpersonation = async () => {
    const { history, endImpersonation } = this.props;

    await endImpersonation();
    history.push('/myTeam');
  };

  render() {
    const {
      auth: {
        currentUser: { isAuthenticated, isFulfilled, isRejected }
      }
    } = this.props;

    return (
      (isFulfilled || isRejected) &&
      (isAuthenticated ? (
        <>
          <Header
            handleSignOut={this.signOut}
            handleEndImpersonation={this.handleEndImpersonation}
          />
          <Switch>
            <Route exact path="/" component={Campaigns} />
            <Route exact path="/index.html" component={Campaigns} />
            <Route exact path="/campaigns" component={Campaigns} />
            <Route exact path="/campaigns/new" component={CreateCampaign} />
            <Route exact path="/campaigns/edit/:id" component={EditCampaign} />
            <Route exact path="/leads" component={Leads} />
            <Route exact path="/leads/:id" component={Lead} />
            <Route exact path="/prospects" component={Prospects} />
            <Route exact path="/prospects/:id" component={Prospect} />
            <Route exact path="/myTeam" component={Users} />
            <Route exact path="/myTeam/new" component={CreateUsers} />
          </Switch>
        </>
      ) : (
        <Redirect to={{ pathname: NON_AUTHORIZED_REDIRECT_URL }} />
      ))
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  getAuthUser: () => dispatch(getAuthenticatedUser()),
  signOut: () => {
    dispatch(resetCurrentUser());
    dispatch(logout());
  },
  endImpersonation: () => dispatch(endImpersonationAction())
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DefaultLayout);
