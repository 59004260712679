import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faComment,
  faCog,
  faTrashAlt,
  faPause,
  faPencilAlt,
  faFolderPlus
} from '@fortawesome/free-solid-svg-icons';
import '../../css/campaign.css';

const modalNames = {
  DELETE: 'DELETE',
  TEST: 'TEST'
};

class Campaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddTestLeadModalOpen: false,
      isDeleteModalOpen: false,
      showMenu: false,
      canSubmit: false,
      prospect: {}
    };

    this.ref = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.captureInput = this.captureInput.bind(this);
    this.triggerFlyout = this.triggerFlyout.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.validateFormData = this.validateFormData.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  triggerFlyout = () => {
    const { showMenu } = this.state;

    if (!showMenu) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showMenu: !prevState.showMenu
    }));
  };

  toggle = modalName => {
    const { isAddTestLeadModalOpen, isDeleteModalOpen } = this.state;

    if (modalName === modalNames.DELETE) {
      this.setState({ isDeleteModalOpen: !isDeleteModalOpen });
    } else if (modalName === modalNames.TEST) {
      this.setState({
        isAddTestLeadModalOpen: !isAddTestLeadModalOpen,
        prospect: {}
      });
    } else {
      console.log(`${modalName} IS INVALID`);
    }
  };

  captureInput = async e => {
    const { name, value } = e.target;

    const { prospect } = this.state;

    prospect[name] = value;

    await this.setState({
      prospect
    });

    this.validateFormData();
  };

  validateFormData = () => {
    const { prospect } = this.state;

    let canSubmit = false;

    if (
      !!prospect.email &&
      !!prospect.phoneNumber &&
      !!prospect.firstName &&
      !!prospect.lastName
    )
      canSubmit = true;

    this.setState({
      canSubmit
    });
  };

  submitFormData = async () => {
    const { prospect } = this.state;
    const { createTestLead, campaign } = this.props;

    await createTestLead({ ...prospect, campaignId: campaign._id });
    this.toggle(modalNames.TEST);
  };

  displayTime = (interval, timeType) => {
    let str = '';
    switch (timeType) {
      case 'minutes':
        str = 'min';
        break;
      case 'hours':
        str = 'hr';
        break;
      case 'days':
        str = 'day';
        break;
      default:
        str = 'hr';
        break;
    }
    return `${interval} ${interval === 1 ? str : `${str}s`}`;
  };

  handleOutsideClick() {
    if (this.ref.current !== null) {
      return;
    }

    this.triggerFlyout();
  }

  render() {
    const {
      showMenu,
      isAddTestLeadModalOpen,
      isDeleteModalOpen,
      canSubmit,
      prospect
    } = this.state;

    const {
      index,
      campaign: { _id, name, touchpoints, running },
      redirectToEdit,
      toggleRunning,
      handleDeleteCampaign,
      inImpersonationMode
    } = this.props;

    return (
      <Row className="flex border border-secondary rounded my-4" ref={this.ref}>
        <Col className="pl-3">
          <Row className="py-3 d-flex align-items-center">
            <Col className="d-flex align-items-center">
              <p className="listItemName text-primary">{name}</p>
            </Col>
            <Col xs="12" md="9" className="d-flex flex-wrap align-items-center">
              {touchpoints.map((touchpoint, i) => {
                const { timeType, interval } = touchpoint.intervalTime;

                return (
                  <>
                    {i !== 0 ? (
                      <svg
                        width="110"
                        height="55"
                        viewBox="0 0 51 18"
                        fill="#6c757d"
                        style={{ strokeWidth: '0px', backgroundColor: 'white' }}
                      >
                        <path d="M39 2h-33l6 7-6 7h33l6-7z" />
                        <text
                          x="50%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fill="white"
                          style={{ fontSize: '6px', zIndex: '1' }}
                        >
                          {this.displayTime(interval, timeType)}
                        </text>
                      </svg>
                    ) : (
                      false
                    )}
                    <div
                      className="bubble d-flex justify-content-center align-items-center bg-primary"
                      style={{
                        width: '45px',
                        height: '45px',
                        borderRadius: '50%'
                      }}
                    >
                      {touchpoint.type === 'sms' ? (
                        <FontAwesomeIcon
                          className="campaign-sms"
                          icon={faComment}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="campaign-envelope"
                          icon={faEnvelope}
                        />
                      )}
                    </div>
                  </>
                );
              })}
            </Col>
          </Row>
        </Col>

        <Col
          onClick={() => this.triggerFlyout()}
          data-testcafe={`campaign-flyout-menu-button-${index}`}
          className="campaign-settings col-auto d-flex border-left border-secondary justify-content-center align-items-center flyout-container"
        >
          <FontAwesomeIcon
            icon={faCog}
            className="campaign-cog text-secondary"
          />
          {showMenu ? (
            <div className="flyout">
              <Button
                color="link"
                className="flyout-link"
                onClick={() => this.toggle(modalNames.TEST)}
                disabled={inImpersonationMode}
              >
                <FontAwesomeIcon className="mr-2" icon={faFolderPlus} />
                Test Campaign
              </Button>
              <Button
                color="link"
                className="flyout-link"
                disabled={inImpersonationMode}
                onClick={() => toggleRunning(_id, !running)}
                data-testcafe={`campaign-flyout-menu-running-button-${index}`}
              >
                <FontAwesomeIcon className="mr-2" icon={faPause} />
                {running ? 'Pause Campaign' : 'Resume Campaign'}
              </Button>
              <Button
                color="link"
                className="flyout-link"
                disabled={inImpersonationMode}
                onClick={() => redirectToEdit(_id)}
                data-testcafe={`campaign-flyout-menu-edit-button-${index}`}
              >
                <FontAwesomeIcon className="mr-2" icon={faPencilAlt} />
                Edit Campaign
              </Button>
              <Button
                color="link"
                className="flyout-link"
                disabled={inImpersonationMode}
                onClick={() => this.toggle(modalNames.DELETE)}
                data-testcafe={`campaign-flyout-menu-delete-button-${index}`}
              >
                <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />
                Delete Campaign
              </Button>
            </div>
          ) : (
            <></>
          )}
        </Col>
        <Modal
          centered
          isOpen={isAddTestLeadModalOpen}
          toggle={() => this.toggle(modalNames.TEST)}
          style={{ minWidth: '30vw' }}
        >
          <ModalHeader toggle={() => this.toggle(modalNames.TEST)} />
          <ModalBody style={{ minHeight: '35vh' }}>
            <Form>
              <FormGroup>
                <Input
                  type="text"
                  name="firstName"
                  value={prospect.firstName}
                  onInput={this.captureInput}
                  placeholder="First Name"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="lastName"
                  value={prospect.lastName}
                  onInput={this.captureInput}
                  placeholder="Last Name"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="email"
                  value={prospect.email}
                  onInput={this.captureInput}
                  placeholder="Email"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="phoneNumber"
                  value={prospect.phoneNumber}
                  onInput={this.captureInput}
                  placeholder="Phone Number"
                />
              </FormGroup>
              <FormGroup className="d-flex align-items-center justify-content-center">
                <Button
                  color="primary"
                  onClick={this.submitFormData}
                  disabled={!canSubmit || inImpersonationMode}
                  style={{
                    width: '100%'
                  }}
                >
                  <FontAwesomeIcon className="mr-2" icon={faFolderPlus} />
                  Add Test Lead
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          toggle={() => this.toggle(modalNames.DELETE)}
          style={{ minWidth: '30vw' }}
        >
          <ModalHeader toggle={() => this.toggle(modalNames.DELETE)}>
            Are you sure?
          </ModalHeader>
          <ModalBody
            style={{ display: 'flex', justifyContent: 'space-around' }}
          >
            <Button
              color="secondary"
              onClick={() => this.toggle(modalNames.DELETE)}
              style={{
                width: '30%'
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => handleDeleteCampaign(_id)}
              disabled={inImpersonationMode}
              style={{
                width: '30%'
              }}
            >
              Confirm
            </Button>
          </ModalBody>
        </Modal>
      </Row>
    );
  }
}

export default Campaign;
