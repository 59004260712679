import api from '../../api';
import { PROSPECT_FIND, PROSPECT_FINDBYID, PROSPECT_CREATE } from './types';

export const getProspectsAction = params => ({
  type: PROSPECT_FIND,
  payload: api.prospect.find(params)
});

export const getProspectAction = id => ({
  type: PROSPECT_FINDBYID,
  payload: api.prospect.findById(id)
});

export const createProspectAction = params => ({
  type: PROSPECT_CREATE,
  payload: api.prospect.create(params)
});
