import api from '../../api';
import { RESPONSE_TIME_FIND, RESPONSE_TIME_ALERT } from './types';

export const getResponseTimesAction = params => ({
  type: RESPONSE_TIME_FIND,
  payload: api.responseTime.find(params)
});

export const getResponseTimeAlertsAction = params => ({
  type: RESPONSE_TIME_ALERT,
  payload: api.responseTime.find(params)
});
