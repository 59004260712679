import React from 'react';
// import { Container, Col, Row } from 'reactstrap';
// import { compose } from 'redux';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import _isEmpty from 'lodash.isempty';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faSort,
//   faSortUp,
//   faSortDown
// } from '@fortawesome/free-solid-svg-icons';
// import { STATUS } from '../../constants';

// import { getProspectsAction } from '../../store/actions/prospect';
// import { getCampaignsAction } from '../../store/actions/campaign';

// import LeadListItem from '../../components/Leads/LeadListItem';
import SetupLeads from '../../components/Leads/SetupLeads';

import '../../css/prospects.css';

const Leads = () => {
  // constructor(props) {
  //   super(props);

  //   this.getLeads = this.getLeads.bind(this);
  // }

  // async componentDidMount() {
  //   await this.refresh();
  // }

  // getLeads = async sort => {
  //   const {
  //     getLeads,
  //     getCampaigns,
  //     leads: { data: response },
  //     currentUserData: {
  //       data: { attributes }
  //     },
  //     impersonatedData
  //   } = this.props;

  //   const inImpersonationMode = !_isEmpty(impersonatedData);

  //   const userId = inImpersonationMode
  //     ? impersonatedData._id
  //     : attributes['custom:externalId'];

  //   const { params } = response.params
  //     ? response
  //     : { params: { sort: '-createdAt' } };

  //   const {
  //     value: { response: leadsResponse }
  //   } = await getLeads({
  //     ordering: sort || params.sort,
  //     filter: JSON.stringify({ userId, status: STATUS.LEAD })
  //   });

  //   const { data: leads } = leadsResponse || { data: [] };

  //   const campaignIds = leads.length
  //     ? leads.map(lead => lead.campaignId)
  //     : leads;

  //   await getCampaigns({ filter: { campaignIds } });
  // };

  // async refresh() {
  //   const {
  //     getLeads,
  //     getCampaigns,
  //     currentUserData: {
  //       data: { attributes }
  //     },
  //     impersonatedData
  //   } = this.props;

  //   try {
  //     const inImpersonationMode = !_isEmpty(impersonatedData);
  //     const userId = inImpersonationMode
  //       ? impersonatedData._id
  //       : attributes['custom:externalId'];

  //     const {
  //       value: { response }
  //     } = await getLeads({
  //       filter: JSON.stringify({ userId, status: STATUS.LEAD })
  //     });

  //     const { data: leads } = response || { data: [] };

  //     const campaignIds = leads.length
  //       ? leads.map(lead => lead.campaignId)
  //       : leads;

  //     await getCampaigns({ filter: { campaignIds } });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // renderSortIcon(order = 'createdAt', sortValue, sortText) {
  //   const isDescending = order.includes('-');
  //   const orderValue = order.replace('-', '');

  //   let icon = faSort;
  //   let sortOrder = sortValue;

  //   if (orderValue === sortValue) {
  //     icon = isDescending ? faSortDown : faSortUp;
  //     sortOrder = isDescending ? sortValue : `-${sortValue}`;
  //   }

  //   return (
  //     <>
  //       <Col className="pl-3">
  //         <Row className="py-3 d-flex align-items-center">
  //           <Col className="d-flex align-items-center">
  //             <p
  //               className="listItemName text-secondary"
  //               data-testcafe={`sort-${sortValue}`}
  //               onClick={() => this.getLeads(sortOrder)}
  //             >
  //               {`${sortText} `}
  //               <FontAwesomeIcon
  //                 data-testcafe={`sort-${sortValue}-icon`}
  //                 icon={icon}
  //               />
  //             </p>
  //           </Col>
  //         </Row>
  //       </Col>
  //     </>
  //   );
  // }

  // render() {
  // const {
  //   leads: {
  //     isFulfilled,
  //     data: { response },
  //     data: { params }
  //   },
  //   campaigns: {
  //     isFulfilled: campaignIsFulfilled,
  //     data: { response: campaignResponse }
  //   }
  // } = this.props;

  // const { data: leads } = response || { data: [] };
  // const { ordering } = params || { ordering: '-createdAt' };
  // const { data: campaigns } = campaignResponse || { data: [] };

  return (
    <>
      <SetupLeads />
      {/* {isFulfilled && !leads.length ? (
        ) : (
          <Container className="d-flex justify-content-center">
            <Col style={{ width: '90vw' }}>
              {isFulfilled && campaignIsFulfilled && (
                <Row className="flex rounded">
                  {this.renderSortIcon(ordering, 'firstName', 'Fullname')}
                  <Col className="pl-3">
                    <Row className="py-3 d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <p className="listItemName text-secondary">Campaign</p>
                      </div>
                    </Row>
                  </Col>
                </Row>
              )}
              {isFulfilled &&
                campaignIsFulfilled &&
                leads.map((lead, index) => (
                  <LeadListItem
                    index={index}
                    lead={lead}
                    campaigns={campaigns}
                  />
                ))}
            </Col>
          </Container>
        )} */}
    </>
  );
  // }
};

// const mapStateToProps = state => ({
//   leads: state.prospect.find,
//   campaigns: state.campaign.find,
//   currentUserData: state.auth.currentUser,
//   impersonatedData: state.impersonation.data
// });

// const mapDispatchToProps = dispatch => ({
//   getLeads: params => dispatch(getProspectsAction(params)),
//   getCampaigns: params => dispatch(getCampaignsAction(params))
// });

export default Leads;

// compose(
//   withRouter,
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )
// )(Leads);
