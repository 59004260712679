import api from '../../api';
import {
  CAMPAIGN_FIND,
  CAMPAIGN_FINDBYID,
  CAMPAIGN_CREATE,
  CAMPAIGN_UPDATE,
  CAMPAIGN_DELETE
} from './types';

export const getCampaignsAction = params => ({
  type: CAMPAIGN_FIND,
  payload: api.campaign.find(params)
});

export const getCampaignAction = id => ({
  type: CAMPAIGN_FINDBYID,
  payload: api.campaign.findById(id)
});

export const createCampaignAction = params => ({
  type: CAMPAIGN_CREATE,
  payload: api.campaign.create(params)
});

export const updateCampaignAction = (id, params) => ({
  type: CAMPAIGN_UPDATE,
  payload: api.campaign.update(id, params)
});

export const deleteCampaignAction = id => ({
  type: CAMPAIGN_DELETE,
  payload: api.campaign.delete(id)
});
