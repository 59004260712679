import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Login from './views/login';
import ForgotPassword from './views/Password/ForgotPassword';
import ChangePassword from './views/Password/ChangePassword';
import configureStore from './store';
import DefaultLayout from './components/DefaultLayout';
import App from './components/App';

const history = createBrowserHistory();
const store = configureStore();

const Root = () => (
  <Provider store={store}>
    <Router history={history}>
      <App>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Pasword"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/change-password"
            name="Change Pasword"
            component={ChangePassword}
          />
          <Route path="/" component={DefaultLayout} />
        </Switch>
      </App>
    </Router>
  </Provider>
);

export default Root;
