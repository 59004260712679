import api from '../../api';
import { MESSAGE_FIND, MESSAGE_FINDBYID, MESSAGE_CREATE } from './types';

export const getMessagesAction = params => ({
  type: MESSAGE_FIND,
  payload: api.message.find(params)
});

export const getMessageAction = id => ({
  type: MESSAGE_FINDBYID,
  payload: api.message.findById(id)
});

export const createMessageAction = params => ({
  type: MESSAGE_CREATE,
  payload: api.message.create(params)
});
