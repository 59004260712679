import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Alert,
  Button,
  Col,
  Row,
  Input,
  Label
} from 'reactstrap';

import '../../css/user-form.css';

class UserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canSubmit: false,
      firstName: '',
      lastName: '',
      email: ''
    };

    this.captureInput = this.captureInput.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async onSubmit() {
    const { firstName, lastName, email } = this.state;
    const { onSubmit } = this.props;

    await onSubmit({
      firstName,
      lastName,
      email
    });
  }

  async onCancel() {
    const { onCancel } = this.props;

    await this.setState({
      firstName: '',
      lastName: '',
      email: ''
    });

    await onCancel();
  }

  validateForm() {
    const { firstName, lastName, email } = this.state;

    if (firstName && lastName && email) {
      this.setState({
        canSubmit: true
      });
    } else {
      this.setState({
        canSubmit: false
      });
    }
  }

  async captureInput(e) {
    const {
      target: { name, value }
    } = e;

    await this.setState({
      [name]: value
    });

    this.validateForm();
  }

  render() {
    const {
      createUser: { isRejected: userCreateIsRejected, error: userCreateError }
    } = this.props;
    const { firstName, lastName, email, canSubmit } = this.state;

    return (
      <>
        <Row className="justify-content-between pt-2">
          <div className="d-flex" />
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex">
              <Button
                color="primary"
                className="m-1"
                type="submit"
                disabled={!canSubmit}
                onClick={this.onSubmit}
              >
                Save User
              </Button>
              <Button color="secondary" className="m-1" onClick={this.onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Row>
        {userCreateIsRejected && (
          <Alert color="danger">
            {`Error: ${
              typeof userCreateError.response.data.message !== 'undefined'
                ? userCreateError.response.data.message
                : userCreateError.response.data
            }`}
          </Alert>
        )}
        <Row id="createUserFormContainer">
          <Form style={{ width: '100%' }}>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">First Name</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  id="text-input"
                  name="firstName"
                  placeholder="Enter First Name"
                  onChange={this.captureInput}
                  defaultValue={firstName}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="textarea-input">Last Name</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  id="text-input"
                  name="lastName"
                  placeholder="Enter Last Name"
                  onChange={this.captureInput}
                  defaultValue={lastName}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="email-input">Email</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="email"
                  id="email-input"
                  name="email"
                  placeholder="Enter Email"
                  autoComplete="email"
                  onChange={this.captureInput}
                  defaultValue={email}
                />
              </Col>
            </FormGroup>
          </Form>
        </Row>
      </>
    );
  }
}

export default UserForm;
