import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';
import { Helmet } from 'react-helmet';

import { getTenantsAction, getTenantAction } from '../../store/actions/tenant';
import getCurrentTenantDomain from '../../utils/whitelabel';

class App extends Component {
  componentDidMount = async () => {
    const {
      getTenants,
      getTenant,
      tenant: {
        findById: { data: tenant }
      }
    } = this.props;

    const domain = getCurrentTenantDomain();

    try {
      if (_isEmpty(tenant) || tenant.domain !== domain) {
        await getTenant(domain);

        if (domain === 'default') {
          getTenants();
        }
      }
    } catch (err) {
      // do nothing for now. Just falls back to
      // normal functionality.
      console.log(err);
    }
  };

  render() {
    const {
      children,
      tenant: {
        findById: {
          isFulfilled,
          data: { data }
        }
      }
    } = this.props;

    const faviconUrl =
      isFulfilled && data && data.faviconUrl ? data.faviconUrl : '/favicon.ico';
    const title = isFulfilled ? data.name : 'Go Fetch It';

    return (
      <>
        <Helmet defaultTitle={title}>
          <link rel="shortcut icon" href={faviconUrl} />
        </Helmet>
        {isFulfilled && children}
      </>
    );
  }
}

const mapStateToProps = state => ({
  tenant: state.tenant
});

const mapDispatchToProps = dispatch => ({
  getTenants: () => dispatch(getTenantsAction()),
  getTenant: id => dispatch(getTenantAction(id))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(App);
