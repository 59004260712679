import React, { Component } from 'react';
import { Container, Col } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';

import CampaignForm from '../../components/Campaigns/CampaignForm';
import {
  updateCampaignAction,
  getCampaignAction
} from '../../store/actions/campaign';

class EditCampaign extends Component {
  constructor(props) {
    super(props);

    this.editCampaign = this.editCampaign.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      },
      findCampaignById
    } = this.props;

    await findCampaignById(id);
  }

  editCampaign = async data => {
    const {
      editCampaign,
      history,
      match: {
        params: { id }
      }
    } = this.props;

    try {
      await editCampaign(id, data);
      history.push('/campaigns');
    } catch (err) {
      console.log(err);
    }
  };

  onCancel = async () => {
    const { history } = this.props;

    history.push('/campaigns');
  };

  render() {
    const {
      history,
      currentUser: { isFulfilled, data: user },
      campaign: {
        isFulfilled: campaignIsFulfilled,
        data: { data: campaign }
      },
      impersonatedData
    } = this.props;

    if (!_isEmpty(impersonatedData)) history.push('/campaigns');

    return (
      <Container className="d-flex justify-content-center">
        <Col style={{ width: '90vw' }}>
          {isFulfilled && campaignIsFulfilled && (
            <CampaignForm
              user={user}
              campaign={campaign}
              onSubmit={this.editCampaign}
              onCancel={this.onCancel}
            />
          )}
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  campaign: state.campaign.findById,
  impersonatedData: state.impersonation.data
});

const mapDispatchToProps = dispatch => ({
  findCampaignById: id => dispatch(getCampaignAction(id)),
  editCampaign: (id, params) => dispatch(updateCampaignAction(id, params))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditCampaign);
