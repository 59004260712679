import React, { PureComponent } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';

class ForgotPasswordForm extends PureComponent {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  }

  handleSubmit() {
    const { onSubmit } = this.props;

    onSubmit(this.state);
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }

  render() {
    const { isSubmitting, isPending, errorMessage } = this.props;

    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Container>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <Row className="justify-content-center">
            <Col md="9" lg="7" xl="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <Form onKeyDown={e => this.handleKeyDown(e)}>
                    <h1>Reset Password?</h1>
                    <p className="text-muted">Reset your password</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        name="email"
                        placeholder="Email address"
                        autoComplete="email"
                        onChange={this.handleInputChange}
                      />
                    </InputGroup>
                    <Button
                      color="primary"
                      block
                      disabled={isSubmitting && isPending}
                      onClick={this.handleSubmit}
                    >
                      Reset Password
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ForgotPasswordForm;
