import api from '../../api';
import { USER_FIND, USER_FINDBYID, USER_CREATE, USER_UPDATE } from './types';

export const getUsersAction = params => ({
  type: USER_FIND,
  payload: api.user.find(params)
});

export const getUserAction = id => ({
  type: USER_FINDBYID,
  payload: api.user.findById(id)
});

export const createUsersAction = params => ({
  type: USER_CREATE,
  payload: api.user.create(params)
});

export const updateUserAction = (id, params) => ({
  type: USER_UPDATE,
  payload: api.user.update(id, params)
});
