import defaultBackgroundImage from './assets/main-background.jpeg';
import defaultLogoImage from './assets/logo.png';

export const NON_AUTHORIZED_REDIRECT_URL = '/login';
export const LOGGED_OUT_REDIRECT_URL = '/login';
export const LOGGED_IN_REDIRECT_URL = '/campaigns';
export const CHANGE_PASSWORD_REDIRECT_URL = '/change-password';
export const RESET_PASSWORD_REDIRECT_URL = '/forgot-password';
export const SUCCESSFUL_PASSWORD_RESET_REDIRECT = '/login';

export const DEFAULT_BACKGROUND_IMAGE = defaultBackgroundImage;
export const DEFAULT_LOGO_IMAGE = defaultLogoImage;

export const ROLES = {
  OWNER: 'Owner',
  TEAM_MEMBER: 'Team Member'
};

export const STATUS = {
  LEAD: 'Lead',
  PROSPECT: 'Prospect'
};

export const MESSAGE_STATUS = {
  RECEIVED: 'Received',
  DELIVERED: 'Delivered',
  OUTGOING: 'Outgoing'
};

export const MESSAGE_TYPE = {
  SMS: 'SMS',
  EMAIL: 'Email'
};

export const MAX_SMS_MESSAGE_LENGTH = 160;

export const MAX_INTERVAL_VALUE = 99;

export const EXPAND_AT = 263;
export const ELLIPSIZE_AT = 260;

export const MOMENT_FORMATS = {
  MONTH_DAY: 'MMM D',
  MONTH_DAY_TIME_PERIOD: 'MMM Do, h:mm A',
  TIME_ELAPSED: 'y [years] M [months] w [weeks] d[d] h[hrs] m [mins] s [s]'
};

export const TOUCHPOINT_DEFAULT_MESSAGE =
  'Hi {firstname}, \n\nPlease feel free to respond to this message for that amazing offer. \n\nThank you!';
