import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup
} from 'reactstrap';

import '../../css/prospect.css';
import { MESSAGE_TYPE } from '../../constants';

class SendMessageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      canSubmit: false,
      messageBody: '',
      messageSubject: ''
    };

    this.toggle = this.toggle.bind(this);
    this.captureInput = this.captureInput.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.validateFormData = this.validateFormData.bind(this);
  }

  toggle = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen, messageBody: '', messageSubject: '' });
  };

  captureInput = async e => {
    const { name, value } = e.target;

    await this.setState({
      [name]: value
    });

    this.validateFormData();
  };

  validateFormData = () => {
    const { messageBody, messageSubject } = this.state;

    let canSubmit = false;

    if (!!messageBody && !!messageSubject) canSubmit = true;

    this.setState({
      canSubmit
    });
  };

  submitFormData = async () => {
    const { messageBody, messageSubject } = this.state;

    const { sendMessage } = this.props;

    await sendMessage({
      body: messageBody,
      subject: messageSubject,
      messageType: MESSAGE_TYPE.EMAIL
    });
    this.toggle();
  };

  render() {
    const { isOpen, canSubmit, messageBody, messageSubject } = this.state;

    const { disabled } = this.props;

    return (
      <>
        <Button
          disabled={disabled}
          onClick={this.toggle}
          className="m-2"
          color="primary"
          data-testcafe="send-email-modal-button"
        >
          <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
          Email
        </Button>
        <Modal
          centered
          isOpen={isOpen}
          toggle={this.toggle}
          style={{ minWidth: '60vw' }}
        >
          <ModalHeader toggle={this.toggle} />
          <ModalBody style={{ minHeight: '60vh' }}>
            <Form>
              <FormGroup>
                <Input
                  type="text"
                  name="messageSubject"
                  value={messageSubject}
                  onInput={this.captureInput}
                  placeholder="Subject"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  name="messageBody"
                  value={messageBody}
                  style={{ height: '40vh' }}
                  onInput={this.captureInput}
                  placeholder="Body"
                />
              </FormGroup>
              <FormGroup className="d-flex align-items-center justify-content-center">
                <p className="text-secondary">
                  Your email footer will be appended to this message
                </p>
              </FormGroup>
              <FormGroup className="d-flex align-items-center justify-content-center modalSubmitButtonContainer">
                <Button
                  color="primary"
                  onClick={this.submitFormData}
                  disabled={!canSubmit || disabled}
                  className="modalSubmitButton"
                >
                  <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
                  Send
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default SendMessageModal;
