/* eslint-disable import/no-extraneous-dependencies */
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import axios from 'axios';

import { ENDPOINT_TYPE } from '../store/constants';

Amplify.configure({
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AMPLIFY_REGION,
  aws_user_pools_id: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AMPLIFY_AUTH_APP_ID
});

const endpointUrl = (type, url) =>
  [process.env.REACT_APP_API_URL, type, url].join('/');

const publicEndpointUrl = url => endpointUrl(ENDPOINT_TYPE.PUBLIC, url);
const authEndpointUrl = url => endpointUrl(ENDPOINT_TYPE.AUTH, url);

// Add auth credentials to all outgoing API requests.
axios.interceptors.request.use(
  async config => {
    if (!config.url.includes('/auth/')) {
      return config;
    }

    const session = await Auth.currentSession();

    // eslint-disable-next-line no-param-reassign
    config.headers.common.authorization = session.idToken.jwtToken;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default {
  auth: {
    signIn: credentials => {
      const { username, password } = credentials;
      return Auth.signIn(username, password);
    },

    completeNewPassword: (user, password) =>
      Auth.completeNewPassword(user, password),

    currentUser: () => Auth.currentAuthenticatedUser(),

    forgotPassword: username => Auth.forgotPassword(username),

    forgotPasswordConfirm: params => {
      const { username, code, newPassword } = params;
      return Auth.forgotPasswordSubmit(username, code, newPassword);
    },
    changePassword: (user, oldPassword, newPassword) =>
      Auth.changePassword(user, oldPassword, newPassword),

    logout: () => Auth.signOut()
  },
  tenant: {
    find: params =>
      axios
        .get(publicEndpointUrl(`tenants`), { params })
        .then(response => ({ response: response.data, params })),
    findById: id => {
      if (id === 'default') {
        return Promise.resolve({ data: {} });
      }

      return axios
        .get(publicEndpointUrl(`tenants/${id}`))
        .then(response => response.data);
    }
  },
  user: {
    create: params =>
      axios
        .post(authEndpointUrl('users'), params)
        .then(response => response.data),
    find: params =>
      axios
        .get(authEndpointUrl(`users`), { params })
        .then(response => ({ response: response.data, params })),
    findById: id =>
      axios.get(authEndpointUrl(`users/${id}`)).then(response => response.data),
    update: (id, params) =>
      axios
        .put(authEndpointUrl(`users/${id}`), params)
        .then(response => ({ response: response.data, params }))
  },
  campaign: {
    create: params =>
      axios
        .post(authEndpointUrl('campaigns'), params)
        .then(response => response.data),
    find: params =>
      axios
        .get(authEndpointUrl(`campaigns`), { params })
        .then(response => ({ response: response.data, params })),
    findById: id =>
      axios
        .get(authEndpointUrl(`campaigns/${id}`))
        .then(response => response.data),
    update: (id, params) =>
      axios
        .put(authEndpointUrl(`campaigns/${id}`), params)
        .then(response => response.data),
    delete: id =>
      axios
        .delete(authEndpointUrl(`campaigns/${id}`))
        .then(response => response.data)
  },
  prospect: {
    create: params =>
      axios
        .post(authEndpointUrl('prospects'), params)
        .then(response => response.data),
    find: params =>
      axios
        .get(authEndpointUrl(`prospects`), { params })
        .then(response => ({ response: response.data, params })),
    findById: id =>
      axios
        .get(authEndpointUrl(`prospects/${id}`))
        .then(response => response.data)
  },
  message: {
    create: params =>
      axios
        .post(authEndpointUrl('messages'), params)
        .then(response => response.data),
    find: params =>
      axios
        .get(authEndpointUrl(`messages`), { params })
        .then(response => ({ response: response.data, params })),
    findById: id =>
      axios
        .get(authEndpointUrl(`messages/${id}`))
        .then(response => response.data)
  },
  responseTime: {
    find: params =>
      axios
        .get(authEndpointUrl(`response-times`), { params })
        .then(response => ({ response: response.data, params }))
  }
};
